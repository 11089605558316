import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../components/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Popup from 'reactjs-popup'
import ContactForm from '../components/estimate-form'
import { X } from 'react-feather'

const CTAOuterContainer = styled.section`
  padding: 4em 2em;
  height: auto;
  background-size: cover;
  position: relative;
  @media (max-width: 992px) {
    text-align: center;
  }
  .gatsby-image-wrapper {
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: #000;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.5;
    }
  }
  .popup-overlay {
    overflow: scroll !important;
  }
  .popup-content {
    background: #f5f5f5 !important;
    max-width: 767px !important;
    text-align: left !important;
  }
`

const ContentContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Heading = styled.h2`
  margin-bottom: 0.25em;
  color: #fff;
  font-size: calc(0.75vw + 1.15em);
`
const Subheading = styled.p`
  color: #f2f2f2;
  margin: 0;
  font-size: calc(0.07vw + 1em);
`
const CTA = ({
  title,
  customTitle,
  customDesc,
  bgimage,
  serviceHeroImage,
  btn,
  props,
}) => {
  const CTABGImage = () => (
    <GatsbyImage
      image={getImage(bgimage ? bgimage : serviceHeroImage)}
      style={{
        left: '0',
        width: '100%',
        height: '100%',
        top: '0',
        zIndex: '-1',
        position: 'absolute',
      }}
      alt=""
    />
  )

  return (
    <CTAOuterContainer>
      {bgimage || serviceHeroImage ? <CTABGImage /> : null}

      <div className="container">
        <ContentContainer>
          <div>
            <Heading>{customTitle ? customTitle : title}</Heading>
            {customDesc ? <Subheading>{customDesc}</Subheading> : null}
          </div>
          <Popup
            trigger={
              <button
                title={'Get Started with ' + title}
                style={{ borderWidth: '0' }}
                className="border-radius button-style-primary button-style-white-outline getstartedbtn mt-2 border-0 cursor-pointer"
              >
                Get Started!
              </button>
            }
            modal
            closeOnDocumentClick
          >
            {(close) => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: '0', float: 'right' }}
                  className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close()
                  }}
                >
                  <X color={'#333'} strokeWidth={'3'} className="d-block" />
                </button>
                <div className="p-3 py-4 block">
                  <h2
                    className="mb-1 popupheading"
                    style={{ lineHeight: '1.05' }}
                  >
                    Start With a Consultation!
                  </h2>
                  <p
                    style={{
                      color: '#666',
                      lineHeight: '1.5',
                      fontSize: '.9em',
                    }}
                    className="mb-3 popupdesc"
                  >
                    Interested in learning more about our new home construction
                    process? We're here to help. Simply give us a call or fill
                    out this form on our website and we'll take the time to
                    learn all of the details of your dream home.
                  </p>

                  <ContactForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={
                      props.location.href ? props.location.href : 'Not provided'
                    }
                  />
                </div>
              </div>
            )}
          </Popup>
        </ContentContainer>
      </div>
    </CTAOuterContainer>
  )
}

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
}

export default CTA
