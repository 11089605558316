import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EstimateForm from './estimate-form'
import CardGeneral from './card-general'
import HeroRatingStars from './hero-rating-stars'
import { monthNames } from '../helpers/all'

const ContentContainer = styled.div``

const ContentRow = styled.div``

const ContentCol = styled.div`
  padding-top: 130px;
  padding-bottom: 60px;
  padding-right: 45px;
  color: #fff;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  @media (max-width: 767px) {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
`

const HeroSection = styled.section`
  height: auto;
  background-size: cover;
  border-bottom: 5px solid;
  position: relative;

  .gatsby-image-wrapper {
    &:before {
      content: '';
      position: absolute;
      height: 104%;
      width: 100%;
      background: #000;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.5;
    }
  }
  .gatsby-image-wrapper [data-main-image] {
    opacity: 1 !important;
    transition: none !important;
  }
`
const HeroHeading = styled.h1`
  position: relative;

  @media (min-width: 767px) {
    font-size: calc(1.35vw + 1.5em) !important;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
    &:before {
      content: '';
      height: 3px;
      width: 50px;
      position: absolute;
      top: -0.5em;
    }
  }
`

const FormWrapper = styled.div`
  margin-top: 2em;
  @media (max-width: 767px) {
    margin-bottom: ${(props) => props.FORMOFFSETPX * -1}px;
  }
`

const HeroSectionOffset = styled.div`
  @media (max-width: 767px) {
    height: ${(props) => props.FORMOFFSETPX - 10}px;
  }
`

const FormHeading = styled.h3`
  padding-bottom: 0px;
  margin-bottom: 5px;
`

const Tag = styled.div`
  display: none;
  background: #ed811f;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  align-items: center;
  color: white;
  text-transform: uppercase;
  padding: 5px;
  min-width: 30px;
  position: relative;
  position: absolute;
  top: -6px;
  right: 21px;
  box-shadow: 0px 2px 5px 1px hsla(0, 0%, 0%, 0.12);
  transform: rotate(10deg);
  &:after {
    content: '';
    position: absolute;
    display: block;
    right: -19px;
    width: 0;
    height: 0;
    top: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 19px solid #ed811f;
  }
  :before {
    content: '';
    background: white;
    height: 8px;
    width: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    border-radius: 50%;
    right: -5px;
    z-index: 100;
  }
  @media (max-width: 480px) {
    &:after {
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
    }
  }
  p {
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 15px;
  }
`
const BadgeItemsContainer = styled.div`
  margin-top: 20px;
  align-items: center;
  flex-wrap: nowrap;
`

const BadgeItem = styled.div`
  flex: 0 0 25%;
  overflow: hidden;
  max-width: 110px;
  padding: 0.5em 0.75em;
  &:last-child {
    padding-right: 0;
  }
`
const BadgeImage = (props) => {
  return (
    <BadgeItem>
      <GatsbyImage
        image={getImage(props.item)}
        objectFit="contain"
        imgStyle={{ objectFit: 'contain' }}
        className="badgeImage"
        alt={props.item.title}
        loading="eager"
      />
    </BadgeItem>
  )
}

const Badges = (props) => {
  const badgeItems = props.badges.map((item, i) => (
    <BadgeImage item={item} key={i} />
  ))
  return (
    <BadgeItemsContainer className="d-flex">{badgeItems}</BadgeItemsContainer>
  )
}

const ServiceHero = ({
  pageData,
  heroImage,
  serviceTitle,
  excerpt,
  specialOffer,
  offerDisclaimer,
  pageProps,
  badges,
  hideForm,
  hideRating,
  SecondaryColumnComponent,
}) => {
  const FORMOFFSETPX = hideForm || SecondaryColumnComponent ? 0 : 350

  const dateInFiveDays = new Date()
  // deal expires in +5 days
  dateInFiveDays.setDate(dateInFiveDays.getDate() + 5)

  //date day suffix
  const nth = function (d) {
    if (d > 3 && d < 21) return 'th'
    switch (d % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  var dealDateStringOutput =
    monthNames[dateInFiveDays.getUTCMonth() % 12] +
    ' ' +
    dateInFiveDays.getUTCDate() +
    nth(dateInFiveDays.getUTCDate())

  return (
    <>
      <HeroSection className={'hero-Stacked border-color-primary service-hero'}>
        {heroImage && (
          <GatsbyImage
            image={getImage(heroImage)}
            className="heroAreaImg"
            loading="eager"
          />
        )}

        <ContentContainer className="container">
          <ContentRow className="row">
            <ContentCol className="col-lg-6 col-md-6">
              <HeroHeading className="before:background-secondary">
                {serviceTitle}
              </HeroHeading>
              <p>{excerpt}</p>
              {!hideRating && <HeroRatingStars />}
              {badges ? <Badges badges={badges} /> : null}
            </ContentCol>
            {SecondaryColumnComponent && <SecondaryColumnComponent />}
            {(!hideForm || !SecondaryColumnComponent) && (
              <>
                <div className="col-lg-2 d-none d-lg-block" />
                <FormWrapper
                  className="col-lg-4 col-md-6"
                  FORMOFFSETPX={FORMOFFSETPX}
                >
                  <CardGeneral className="cardborder">
                    <Tag>
                      <p>Online Special</p>
                    </Tag>
                    <FormHeading>
                      {specialOffer
                        ? specialOffer
                        : 'Request ' + serviceTitle + ' Consultation'}
                    </FormHeading>

                    <div className="specialsubheading">{offerDisclaimer}</div>

                    <p>Let Us Help You Build The Custom Home Of Your Dreams.</p>

                    <EstimateForm
                      style={'minimal-hero'}
                      showPlaceHolders={true}
                      showLabels={false}
                      pageTitle={serviceTitle}
                      pageProps={pageProps}
                      pageLocation={
                        pageProps.location.href
                          ? pageProps.location.href
                          : 'Not provided'
                      }
                    />
                  </CardGeneral>
                </FormWrapper>
              </>
            )}
          </ContentRow>
        </ContentContainer>
      </HeroSection>
      <HeroSectionOffset FORMOFFSETPX={FORMOFFSETPX} />
    </>
  )
}

export default ServiceHero
